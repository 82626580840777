// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-waist-cn-js": () => import("./../src/pages/waist/cn.js" /* webpackChunkName: "component---src-pages-waist-cn-js" */),
  "component---src-pages-waist-en-js": () => import("./../src/pages/waist/en.js" /* webpackChunkName: "component---src-pages-waist-en-js" */),
  "component---src-pages-waist-es-js": () => import("./../src/pages/waist/es.js" /* webpackChunkName: "component---src-pages-waist-es-js" */),
  "component---src-pages-waist-fr-js": () => import("./../src/pages/waist/fr.js" /* webpackChunkName: "component---src-pages-waist-fr-js" */),
  "component---src-pages-waist-index-js": () => import("./../src/pages/waist/index.js" /* webpackChunkName: "component---src-pages-waist-index-js" */),
  "component---src-pages-waist-jp-js": () => import("./../src/pages/waist/jp.js" /* webpackChunkName: "component---src-pages-waist-jp-js" */),
  "component---src-pages-waist-kr-js": () => import("./../src/pages/waist/kr.js" /* webpackChunkName: "component---src-pages-waist-kr-js" */)
}

